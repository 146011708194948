import "../css/lineRazdel.css";

export default function LineRazdel(params) {
    let mass = [];
    window.addEventListener('scroll', () => {
        document.body.style.setProperty('--scroll',window.scrollY / (document.body.offsetHeight - window.innerHeight));
      }, false);
    for (let i = 0; i < 30; i++) {
        mass[i] = (
            <svg
                width="237"
                height="296"
                viewBox="0 0 237 296"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M116.556 149.235L235 1H116.556L1 149.235L116.556 295H235L116.556 149.235Z"
                    stroke="#000"
                />{" "}
            </svg>
        );
    }
    return (
        <section id="ras1" className="lineRazdel">
            <div className="lineRazdel_cont">
            {mass}
            </div>
            {/* <marquee  scrollamount="30" >
                {mass}
            </marquee> */}
        </section>
    );
}
