import "../css/news.css";

export default function News(params) {
    let news = [1, 2, 3];

    return (
        <section id="news" className="news">
            <div className="container">
                <p className="priziv">Только для вас</p>
                <h2 className="h2">
                    Следите{" "}
                    <span className="redG">
                        за новостями
                    </span>{" "}
                    и <br /> будьте в курсе последних <br />{" "}
                    событий
                </h2>

                <div className="news_list">
                    {news.map((e, i) => (
                        <NewsItems key={e} />
                    ))}
                </div>
            </div>
        </section>
    );
}

export function NewsItems(params) {
    return (
        <div className="news_item">
            <div className="news_item_img">
                <img
                    src={
                        process.env.PUBLIC_URL +
                        "img/news/1.jpg"
                    }
                    alt=""
                />
                <p className="news_item_date">
                    27 февраля 2024
                </p>
            </div>
            <div className="news_item_text">
            <p className="t16">
                Рады приветствовать Вас! Хотим поделиться
                приятной новостью о запуске новой модели...
            </p>

            <a className="t16 news_item_link" href="">
                Читать новость  <img src={process.env.PUBLIC_URL + "img/news/icon.png"} alt="" />
            </a>
            </div>
        </div>
    );
}
