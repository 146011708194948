import axios from "axios";
import { useState } from "react";
import ReactInputMask from "react-input-mask";
import data from "../data.json";
import { KatalogItem } from "./katalog";

export default function FosContakt({
    fos,
    setfos,
    fomName,
    setfomName,
    formImg,
}) {

    const [usoeh, setusoeh] = useState(false);
    const [otprav, setotprav] = useState(false);
    function MailGo(e) {
        e.preventDefault();
        setotprav(true);
        const element = {
            name: e.target[0].value,
            tel: e.target[1].value,
            mail: e.target[2].value,
            type: e.target[3].value,
        };
        if (e.target[1].value.includes("_")) {
            document
                .querySelector("#error_tel")
                .classList.add("error");
            e.target[1].focus();
            console.log("net");
            setotprav(false);
        } else {
            axios({
                method: "POST",
                url: "/mail/mail.php",
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify(element),
            })
                .then((response) => {
                    console.log(response);
                    
                    setusoeh(true);
                    setTimeout(() => {
                        setfos(false);
                        setusoeh(false);
                        setotprav(false);
                    }, 2000);
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
    }

    return (
        <div
            onClick={() => setfos(false)}
            className={
                fos
                    ? "fosContact flex_center active"
                    : "fosContact flex_center"
            }
        >
            {usoeh ? (
                <div className="fosContact_form">
                    <h3 className="h3">
                        Отправка прошла успешно
                    </h3>
                </div>
            ) : (
                <form
                    onSubmit={MailGo}
                    onClick={(e) => e.stopPropagation()}
                    className="fosContact_form"
                    action=""
                >
                    <div className="fosContact_form_content">
                        <h3 className="h3">{fomName}</h3>
                        <p className="t20">
                            Оставьте ваши контакты и мы
                            свяжемся с вами в рабочее время
                        </p>
                        <input
                            className="fosContact_inputBox t20"
                            type="text"
                            name="name"
                            placeholder="Ваше имя"
                            required
                            minLength={2}
                        />
                        <div id="error_tel" className="">
                            <ReactInputMask
                                className="fosContact_inputBox t20"
                                name="tel"
                                placeholder="Номер телефона"
                                mask="+7 (999) 999 99 99"
                                type="text"
                                required
                            />
                        </div>
                        <textarea
                            className="fosContact_area t20"
                            type="text"
                            name="mail"
                            placeholder="Оставте свой вопрос"
                            required
                        />
                        <input
                            type="hidden"
                            name="type"
                            defaultValue={fomName}
                        />
                        <button className="but t20 ">
                            {!otprav ? (
                                "Связаться с нами"
                            ) : (
                                <div className="loading"></div>
                            )}
                        </button>
                    </div>
                    <div className="fosContact_form_info">
                        <div
                            onClick={() => setfos(false)}
                            className="fosContact_exit"
                        ></div>
                        {formImg ? (
                            <>
                                <div className="katalog_content-lists_item">
                                    <div className="katalog_content-lists_item_gal">
                                        <div className="katalog_content-lists_item_gal_items">
                                            <div className="katalog_content-lists_item_gal_items_el">
                                                <img
                                                    src={
                                                        process
                                                            .env
                                                            .PUBLIC_URL +
                                                        "/img/product/previews/" +
                                                        formImg.preview
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="katalog_content-lists_item_text">
                                        <p className="h3 katalog_content-lists_item_text_name">
                                            {/* {kat} */}
                                        </p>
                                        <p className="t16 katalog_content-lists_item_text_name">
                                            <b>
                                                {formImg.name}
                                            </b>
                                        </p>
                                        <p className="t14">
                                            {formImg.textMin}
                                        </p>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <p className="h3">01</p>
                                    <p className="t16">
                                        у нас Выгодные цены
                                    </p>
                                </div>
                                <div>
                                    <p className="h3">02</p>
                                    <p className="t16">
                                        Возможность <br />{" "}
                                        индивидуального
                                        заказа
                                    </p>
                                </div>
                                <div>
                                    <p className="h3">03</p>
                                    <p className="t16">
                                        Соблюдение <br />{" "}
                                        установленных сроков
                                    </p>
                                </div>
                                <div>
                                    <p className="h3">04</p>
                                    <p className="t16">
                                        Быстрая <br />{" "}
                                        удобная доставка
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                </form>
            )}
        </div>
    );
}
