import Ani from "./ani";

export default function Advantages(params) {
    Ani();

    return (
        <section className="advantages">
            <div className="container">
                <p className="priziv">Почему мы?</p>

                <div className="advantages_content">
                        <div>
                        <h2 className="h2">
                            Компания{" "}
                            <span className="redG">
                                «ФЕНИКС»{" "}
                            </span>
                            ваш надёжный поставщик
                            промышленного оборудования
                        </h2>
                        </div>

                        <AdvantagesItem
                                name={"большой опыт работы"}
                                text={
                                    "Что помогает предприятиям повысить производительность и эффективность"
                                }
                            metka={" ani ani-3"}

                                icon={"1.png"}
                            />
                            <AdvantagesItem
                                name={
                                    "Нет неразрешимых задач"
                                }
                                text={
                                    "Бесплатно консультируем постоянных клиентов после окончания срока действия договора"
                                }
                            metka={" ani ani-2"}

                                icon={"2.png"}
                            />
                        <AdvantagesItem
                            name={
                                "Высокая точность и скорость деталей"
                            }
                            text={
                                "Что помогает предприятиям повысить производительность и эффективность"
                            }
                            icon={"3.png"}
                            metka={"bel ani ani-2"}
                        />
                        <AdvantagesItem
                            name={
                                "Долговечность и износостойкость"
                            }
                            text={
                                "Это делает экономически эффективными инвестициями с минимальным обслуживанием идолгим использованием"
                            }
                            metka={" ani ani-4"}

                            icon={"4.png"}
                        />
                        <AdvantagesItem
                            name={
                                "сервисное обслуживание и ремонт"
                            }
                            text={
                                "Выполняем сервисное обслуживание и ремонт (планово-предупредительный, текущий, капитальный с модернизацией)"
                            }
                            icon={"3.png"}
                            metka={"bel last ani ani-4"}
                        />
                </div>
            </div>
        </section>
    );
}

function AdvantagesItem({ name, text, icon, metka='' }) {

    return (
        <div className={"advantages_item " + metka}>
            <h3 className="h3">{name}</h3>
            <p className="t16">{text}</p>
           <div className="advantages_item-img">
           <img
                src={
                    process.env.PUBLIC_URL +
                    "img/advantages/" +
                    icon
                }
                alt=""
            />
           </div>
        </div>
    );
}
