import { useEffect, useState } from "react";
import "../css/katalogPages.css";
import { useParams } from "react-router-dom";
import Footer from "../bloc/footer";
import Header from "../bloc/header";

export default function KAtalogPages({
    setfos,
    setfomName,
    setformImg,
}) {
    const [kat, setkat] = useState();
    const params = useParams();
    const [img, setimg] = useState();

    useEffect(() => {
        fetch("/data/data.json")
            .then((r) => r.json())
            .then((data) => {
                setkat(
                    data[params.proizvod][params.kat][
                        params.id
                    ]
                );
                setimg(
                    data[params.proizvod][params.kat][
                        params.id
                    ].galleri[0]
                );
            });
    }, [params]);
    if (!kat) {
        return "Load...";
    }
    return (
        <>
            <Header />
            <main className="katalogPages">
                <div className="container">
                    <div className="breadCrumbs">
                        <a
                            className="t20 breadCrumbs_link"
                            href="/"
                        >
                            Главная
                        </a>
                        <div className="breadCrumbs_rasdel"></div>
                        <a
                            className="t20 breadCrumbs_link "
                            href=""
                        >
                            {params.proizvod}
                        </a>
                        <div className="breadCrumbs_rasdel"></div>
                        <p className="t20 breadCrumbs_link ">
                            {params.kat}
                        </p>
                        <div className="breadCrumbs_rasdel"></div>
                        <p className="t20 redG breadCrumbs_link active">
                            {kat.name && kat.name}
                        </p>
                    </div>

                    <section className="katalogPages_fase">
                        <div className="katalogPages_fase_slider">
                            <div className="katalogPages_fase_slider_tek">
                                <img
                                    src={
                                        process.env
                                            .PUBLIC_URL +"/img/product/gallery/"+
                                        img
                                    }
                                    alt=""
                                />
                                <div className="katalogPages_fase_slider_chek"></div>
                            </div>
                            <div className="katalogPages_fase_slider_list">
                                {kat.galleri &&
                                    kat.galleri.map(
                                        (e, i) =>
                                            e !== img && (
                                                <div
                                                    key={i}
                                                    onClick={() =>
                                                        setimg(
                                                            e
                                                        )
                                                    }
                                                    className="katalogPages_fase_slider_list_item"
                                                >
                                                    <img
                                                        src={
                                                            process
                                                                .env
                                                                .PUBLIC_URL +"/img/product/gallery/"+
                                                            e
                                                        }
                                                        alt=""
                                                    />
                                                </div>
                                            )
                                    )}
                            </div>
                        </div>
                        <div className="katalogPages_fase_info">
                            <div className="t20 katalogPages_fase_info_kod">
                                Код товара:{" "}
                                {kat.name && kat.name}
                            </div>
                            <div className="katalogPages_fase_info_content">
                                <h1 className="h2">
                                    {kat.textMin &&
                                        kat.textMin}
                                </h1>

                                <div className="katalogPages_fase_info_content_model">
                                    <p className="t16">
                                        Модели
                                    </p>

                                    <div className="katalogPages_fase_info_content_model_rad">
                                        {kat &&
                                            Object.keys(
                                                kat.model
                                            ).map(
                                                (e, i) => (
                                                    <p
                                                        key={
                                                            i
                                                        }
                                                        className="katalogPages_fase_info_content_model_rad_item"
                                                    >
                                                        {e}
                                                    </p>
                                                )
                                            )}
                                    </div>
                                </div>

                                <p className="t16">
                                    {kat.opisanie}
                                </p>
                            </div>
                            <div className="katalogPages_fase_info_option">
                                <button
                                    onClick={() => {
                                        setfomName(
                                            "Быстрый заказ "+  kat.name
                                        );
                                        setfos(true);
                                        setformImg(
                                            kat
                                        );
                                    }}
                                    className="t20 but"
                                >
                                    Быстрый заказ
                                </button>
                                <a
                                    className="katalogPages_fase_info_option_file"
                                    href="http://"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={
                                            process.env
                                                .PUBLIC_URL +
                                            "/img/icon/dovan.png"
                                        }
                                        alt=""
                                    />
                                    Скачать PDF каталог
                                </a>
                            </div>
                        </div>
                    </section>

                    <section className="katalogPages_harekt">
                        <h2 className="h2">
                            <span className="redG">
                                Характеристики
                            </span>{" "}
                            моделей
                        </h2>

                        <div className="katalogPages_harekt-teble">
                            <div className="katalogPages_harekt-teble_column">
                                <div className="t20 katalogPages_harekt-teble_column_item">
                                    Параметры
                                </div>
                                <div className="red  t16 katalogPages_harekt-teble_column_item">
                                    Параметры обработки
                                </div>
                                <div className=" t16 katalogPages_harekt-teble_column_item">
                                    Перемещение по осям
                                    X,Y,Z (мм)
                                </div>
                                <div className=" t16 katalogPages_harekt-teble_column_item">
                                    Ширина линейной
                                    направляющей X,Y,Z (мм)
                                </div>
                                <div className=" t16 katalogPages_harekt-teble_column_item">
                                    Количество ползунков
                                    (X,Y, Z)
                                </div>
                                <div className="red t16 katalogPages_harekt-teble_column_item">
                                    Характеристики стола
                                </div>
                                <div className=" t16 katalogPages_harekt-teble_column_item">
                                    Размер стола (мм)
                                </div>
                                <div className=" t16 katalogPages_harekt-teble_column_item">
                                    Т-образного пазы{" "}
                                </div>
                                <div className=" t16 katalogPages_harekt-teble_column_item">
                                    Максимальная нагрузка на
                                    стол
                                </div>
                                <div className="red t16 katalogPages_harekt-teble_column_item">
                                    Общие параметры
                                </div>
                                <div className=" t16 katalogPages_harekt-teble_column_item">
                                    Длина станка (мм)
                                </div>
                                <div className=" t16 katalogPages_harekt-teble_column_item">
                                    Максимальный диаметр
                                    (мм)
                                </div>
                                <div className=" t16 katalogPages_harekt-teble_column_item">
                                    Вес станка (кг)
                                </div>
                            </div>
                            <div className="katalogPages_harekt-teble_skroll">
                                {kat &&
                                    Object.keys(
                                        kat.model
                                    ).map((e, i) => (
                                        <div key={i} className="katalogPages_harekt-teble_column">
                                            <div className="t20 katalogPages_harekt-teble_column_item">
                                               {e}
                                            </div>
                                            <div className="red t16 katalogPages_harekt-teble_column_item"></div>
                                            <div className=" t16 katalogPages_harekt-teble_column_item">
                                            {kat.model[e].movingAlongTheAxes}
                                            </div>
                                            <div className=" t16 katalogPages_harekt-teble_column_item">
                                               {kat.model[e].widthLinearGuide}
                                            </div>
                                            <div className=" t16 katalogPages_harekt-teble_column_item">
                                            {kat.model[e].slidersCount}
                                            </div>
                                            <div className="red t16 katalogPages_harekt-teble_column_item"></div>
                                            <div className=" t16 katalogPages_harekt-teble_column_item">
                                            {kat.model[e].tableSize}
                                            </div>
                                            <div className=" t16 katalogPages_harekt-teble_column_item">
                                            {kat.model[e].tSlotsSize}
                                            </div>
                                            <div className=" t16 katalogPages_harekt-teble_column_item">
                                            {kat.model[e].maxLoadOnTable}
                                            </div>
                                            <div className="red t16 katalogPages_harekt-teble_column_item"></div>
                                            <div className=" t16 katalogPages_harekt-teble_column_item">
                                            {kat.model[e].machineLength}
                                            </div>
                                            <div className=" t16 katalogPages_harekt-teble_column_item">
                                            {kat.model[e].maxDiameter}
                                            </div>
                                            <div className=" t16 katalogPages_harekt-teble_column_item">
                                            {kat.model[e].machineWeigth}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <Footer />
        </>
    );
}
