import Advantages from "../bloc/advantages";
import Footer from "../bloc/footer";
import Header from "../bloc/header";
import Katalog from "../bloc/katalog";
import Kompani from "../bloc/kompani";
import Konsult from "../bloc/konsult";
import LineRazdel from "../bloc/lineRazdel";
import News from "../bloc/news";
import Slider from "../bloc/slider";

export default function Main({ fos, setfos, setfomName }) {
    return (
        <>
            <Header />
            <main>
                <Slider />
                <Advantages />
                <Katalog />
                <Konsult
                    setfomName={setfomName}
                    setfos={setfos}
                />
                <Kompani />
                <LineRazdel />
                <News />
            </main>{" "}
            <Footer />
        </>
    );
}
