export default function Footer(params) {
    return (
        <footer className="footer">
            <div className="container">
                <a href="/" className="logo">
                    <img
                        className="logo-img"
                        src={
                            process.env.PUBLIC_URL +
                            "/img/logo2.png"
                        }
                        alt=""
                    />
                    <div className="logo-text">
                        <p className="logo-text_name">
                            ООО «Феникс»
                        </p>
                        <p className="logo-text_slog">
                            Передовые точные технологии
                        </p>
                    </div>
                </a>
                <div>
                    <nav className="footer_menu">
                        <a className="t20" href="/#katalog">
                            Товары
                        </a>
                        <a className="t20" href="/#news">
                            Новости
                        </a>
                        <a className="t20" href="/#kompani">
                            О компании
                        </a>
                        <a className="t20" href="/">
                            Контакты
                        </a>
                    </nav>
                    <div className="footer_men2">
                        <nav className="header_navLink tm">
                            <a
                                className="t20 tm-tel"
                                href="tel:+78002221222"
                            >
                                <img
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/icon/tel.png"
                                    }
                                    alt=""
                                />{" "}
                                <b>8(800)222-12-22</b>
                            </a>
                            <a
                                className="t20 tm-mail"
                                href="mailto:fenix@list.com"
                            >
                                <img
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/icon/@.png"
                                    }
                                    alt=""
                                />
                                fenix@list.com
                            </a>
                        </nav>
                        <nav className="header_navLink tr">
                            <a
                                className="header_navLink_icon"
                                href="http://"
                                target="_blank"
                                rel="noopener noreferrer"
                                data-img="/img/icon/watsap.png"
                            >
                                <img
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/icon/watsap.png"
                                    }
                                    alt=""
                                />
                            </a>
                            <a
                                className="header_navLink_icon"
                                href="http://"
                                target="_blank"
                                rel="noopener noreferrer"
                                data-img="/img/icon/tg.png"
                            >
                                <img
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/icon/tg.png"
                                    }
                                    alt=""
                                />
                            </a>
                        </nav>
                    </div>
                </div>
            </div>
        </footer>
    );
}
