import Footer from "./footer";
import Header from "./header";
import "../css/error.css"
export default function Error(params) {
    return (
        <>
            <Header />
            <main className="errorPage">
                <div className="container">
                    
                    <div>
                        <span className="redG">404</span>

                    </div>
                    <div className="errorPage_foot">
                            <div>
                            <p className="h3">Ошибка</p>
                        <p className="t16">
                            Ой...эта страница не найдена
                        </p>
                            </div>
                            <div className="but">
                                <a className="t16" href="/">Вернуться на главную</a>

                            </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}
