import { NavLink } from "react-router-dom";
import "../css/slider.css";

export default function Slider(params) {
    return (
        <section className="slider">
            <div className="container">
                <div className="slider_list">
                    <div className="slider_list_item">
                        <div className="slider_list_item_fon">
                            <div className="slider_list_item_fon_item" style={{background:`url(img/slider/fon1.jpg)`}}>

                            </div>
                          
                        </div>
                        <div className="slider_list_item_content">
                            <div className="slider_list_item_content_text">
                                <p className="priziv">
                                    Новинка
                                </p>
                                <p className="slider_list_item_content_text_name">
                                    <span className="sin">
                                        CREATIVITY
                                    </span>{" "}
                                    F700A
                                </p>
                                <p className="h3">
                                    Вертикальный
                                    высокоскоростной
                                    обрабатывающий центр
                                </p>
                                <NavLink to={`/katalog/1`} className="t16 but">
                                    Подробнее о товаре
                                </NavLink>
                            </div>
                            <div className="slider_list_item_content_img">
                            <img src={process.env.PUBLIC_URL + "img/slider/el.png"} alt="" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
