import "../css/konsult.css";

export default function Konsult({ setfos,setfomName}) {
    
    return (
        <section className="konsult">
            <div className="container">
                <div className="konsult_text">
                <p className="priziv">
                Наша компания поставляет
                </p>
                <p className="h3">
                Запасные части, оснаска и <br /> комплектующие для станков
                </p>
                </div>
                <button onClick={()=>{setfos(true);setfomName("Получить консультацию")}} className="t20 but">
                Получить консультацию
                </button>

                <img className="konsult_decor" src={process.env.PUBLIC_URL + "img/konsult/decor.png"} alt="" />
            </div>
        </section>
    )
}