import { useRef } from "react"
import "../css/kompani.css"

export default function Kompani(params) {
    return(
        <section id="kompani" className="kompani">
            <div className="container">
                <div className="kompani_contentT1">
                    <img className="kompani_contentT1_img" src={process.env.PUBLIC_URL +"img/kompani/1.jpg"} alt="" />
                    <p className="h2">
                    <span className="red">Наша цель </span>- <br />автоматизация ваших рабочих процессов
                    </p>
                    <p className="t16">
                    Зная потребности клиента и, и применяя свои знания и опыт, мы предлагаем качественное и современное оборудование
                    </p>
                </div>
                <div className="kompani_contentT2">
                    <h2 className="priziv">
                    О компании
                    </h2>

                    <p className="h2">
                    Компания <span className="red">«ФЕНИКС»</span> - <br /> надежная, зарекомендованная на рынке компания
                    </p>

                    <p className="t16">
                    «Феникс» реализует комплексные решения в сфере поставки оборудования и запуска металлообрабатывающего производства. В нашем портфеле — станки нескольких групп, включая крупногабаритные. Мы подберем оборудование под ваши задачи, запустим станок на вашем производстве и поможем увеличить срок его полезного использования.


                    </p>
                    <img className="kompani_contentT2_img" src={process.env.PUBLIC_URL +"img/kompani/2.jpg"} alt="" />

                </div>
            </div>
        </section>
    )
}