import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
export default function Katalog(params) {
    const [kat, setkat] = useState();
    const [ind, setind] = useState(0);
    const [gal, setgal] = useState();
    const [count, setCount] = useState(6);

    useEffect(() => {
        fetch("/data/data.json")
        .then(r => r.json())
        .then(data => {
            setkat(data)
            console.log(data);
            
            setgal(data[Object.keys(data)[ind]])
        })
    }, [ind])
 
   useEffect(() => {
 
   }, [count])
//    console.log(gal);
   
    return (
        <section id="katalog" className="katalog">
            <div className="container">
                <h2 className="h2">
                    <span className="redG">
                        Каталог продукции{" "}
                    </span>{" "}
                    <br />
                    высококачественного <br /> оборудования
                </h2>

                <div className="katalog_content">
                    <div className="katalog_content-menu">
                        <div className="katalog_content-menu_item">
                            Станки по производителям
                        </div>
                        {kat &&
                            Object.keys(kat).map((e,i)=> (
                                <label
                                key={i}
                                htmlFor={"m"+i}
                                className="katalog_content-menu_item chek"
                                onClick={()=>setind(i)}
                            >
                                {e}
                                <input
                                    type="radio"
                                    name="mark"
                                    defaultChecked={ind === i && true}
                                    defaultValue={i}
                                    
                                    id={"m"+i}
                                />
                            </label>
                            ))
                        }
                       
                       
                        <div className="katalog_content-menu_item">
                            <button className=" button">
                                Подробнее о товаре
                            </button>
                        </div>
                    </div>
                    <div className="katalog_content-lists">
                        {
                            kat && 
                           Object.keys(gal).map((e,i)=>(
                              gal[e].slice(0, count).map((el,idd)=>(
                                    <KatalogItem key={"item-"+i+"-"+idd} diller={Object.keys(kat)[ind]} kat={e} data={el} id={idd} />
                                ))
                            ))
                        }

                      
                    </div>
                </div>
            </div>
        </section>
    );
}

export function KatalogItem({data, id, kat, diller}) {
    return (
        <div className="katalog_content-lists_item">
            <div className="katalog_content-lists_item_gal">
                <div className="katalog_content-lists_item_gal_items">
                    <div style={{background: `url(${"img/product/previews/"+data.preview})`}} className="katalog_content-lists_item_gal_items_el">
                    </div>
                </div>
                {/* <div className="katalog_content-lists_item_gal_cheked">
                    <span className="active"></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div> */}
                <NavLink
                    to={`/${diller}/${kat}/${id}`}
                    className="but t20"
                >
                    Подробнее
                </NavLink>
            </div>
            <div className="katalog_content-lists_item_text">
                <p className="h3 katalog_content-lists_item_text_name">
                    {kat}
                </p>
                <p className="t16 katalog_content-lists_item_text_name">
                   <b>{data.name}</b>
                </p>
                <p className="t14">
                  {data.textMin}
                </p>
            </div>
        </div>
    );
}
