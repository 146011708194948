import { useEffect, useState } from "react";

export default function Header(params) {
    const [menu, setmenu] = useState(false);
    useEffect(() => {
        if (menu) {
            document.querySelector("body").classList.add("fixed");
        }else{
            document.querySelector("body").classList.remove("fixed");

        }
    }, [menu])
    
    return (
        <header className="header">
            <div className="container">
                <a href="/" className="logo">
                    <img
                        className="logo-img"
                        src={
                            process.env.PUBLIC_URL +
                            "/img/logo.png"
                        }
                        alt=""
                    />
                    <div className="logo-text">
                        <p className="logo-text_name">
                            ООО «Феникс»
                        </p>
                        <p className="logo-text_slog">
                            Передовые точные технологии
                        </p>
                    </div>
                </a>

                <div className={menu ? "header_menu active": "header_menu"}>
                    <nav className="header_navLink">
                        <a className="t20" href="/#katalog">
                            Товары
                        </a>
                        <a className="t20" href="/#news">
                            Новости
                        </a>
                        <a className="t20" href="/#kompani">
                            О компании
                        </a>
                        <a className="t20" href="/">
                            Контакты
                        </a>
                    </nav>
                    <div className="header_rezdel"></div>
                    <nav className="header_navLink tm">
                        <a
                            className="t20 tm-tel"
                            href="tel:+78002221222"
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/icon/tel.png"
                                }
                                alt=""
                            />{" "}
                            <b>8(800)222-12-22</b>
                        </a>
                        <a
                            className="t20 tm-mail"
                            href="mailto:fenix@list.com"
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/icon/@.png"
                                }
                                alt=""
                            />
                            fenix@list.com
                        </a>
                    </nav>
                    <div className="header_rezdel"></div>

                    <nav className="header_navLink ">
                        <div className="header_navLink_ml">
                            <a
                                className="header_navLink_icon"
                                href="http://"
                                target="_blank"
                                rel="noopener noreferrer"
                                data-img="/img/icon/watsap.png"
                            >
                                <img
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/icon/watsap.png"
                                    }
                                    alt=""
                                />
                            </a>
                            <a
                                className="header_navLink_icon"
                                href="http://"
                                target="_blank"
                                rel="noopener noreferrer"
                                data-img="/img/icon/tg.png"
                            >
                                <img
                                    src={
                                        process.env
                                            .PUBLIC_URL +
                                        "/img/icon/tg.png"
                                    }
                                    alt=""
                                />
                            </a>
                        </div>
                        <a
                            className="t20"
                            href="http://"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/icon/local.png"
                                }
                                alt=""
                            />
                            Москва
                        </a>
                    </nav>
                </div>

                <div onClick={()=>setmenu(!menu)}  className={menu ? "header_menu_hamburger active":"header_menu_hamburger"}>
  <div className="top-bun"></div>
  <div className="meat"></div>
  <div className="bottom-bun"></div>
</div>
            </div>
        </header>
    );
}
