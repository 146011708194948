import React from 'react';
import ReactDOM from 'react-dom/client';
import "./css/style.css"
import "./font/creteRound/stylesheet.css"
import "./font/inter/stylesheet.css"

import "./css/media.css"

import { AppRouste } from './roting';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <>
    
    <AppRouste/>
    

    </>
  </React.StrictMode>
);


