import {
    RouterProvider,
    createBrowserRouter,
} from "react-router-dom";
import Main from "./page/main";
import {  useState } from "react";
import KAtalogPages from "./page/katalogItemPages";
import ScrollToTop from "./bloc/scrilToTop";
import FosContakt from "./bloc/fos";
import Error from "./bloc/404";

export function AppRouste(params) {
    const [fos, setfos] = useState(false);
    const [fomName, setfomName] = useState(null);
    const [formImg, setformImg] = useState(null);

    // useEffect(() => {}, [fos]);

    const router = createBrowserRouter([
        {
            path: "/",
            errorElement:<Error/>,
            children:[
                {
                    path:"",element: (
                        <>
                            <ScrollToTop>
                                <Main setfomName={setfomName} fos={fos} setfos={setfos}/>
                                <FosContakt fomName={fomName} setfomName={setfomName} fos={fos} setfos={setfos}/>
                                {/* <Ani/> */}
                            </ScrollToTop>
                        </>
                    ),
                }
            ]
            
        },
        {
            path: "/:proizvod/:kat/:id",
            errorElement:<Error/>,
            children:[
                {
                    path:"",element: (
                        <>
                            <ScrollToTop>
                        <KAtalogPages setformImg={setformImg} setfomName={setfomName}  setfos={setfos}/>
                        <FosContakt formImg={formImg}  fomName={fomName} setfomName={setfomName} fos={fos} setfos={setfos}/>
                    </ScrollToTop>
                        </>
                    ),
                }
            ]
            
        },
       
       
    ]);

    return <RouterProvider router={router} />;
}
